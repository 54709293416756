<template>
  <div class="box">
    <div class="tabbar" :class="navBarFixed ? 'navBarFixed' : ''">
      <img @click="goback" src="../../../../static/common/logo.png" alt="" />
    </div>
    <div class="banner">
      <img src="/subject/live/banner.jpg" alt="" />
    </div>
    <div class="BIG_BOX">
      <div class="centerView">
        <div class="title">
          <img src="/subject/live/title.png" alt="" />
          <p
            @click="
              () => {
                activityDetailsDialog = true;
              }
            "
          >
            活动详情
          </p>
        </div>
        <div class="groupList">
          <div class="groupItem">
            <h1>如何参与</h1>
            <p>活动期间，前往直播间与老师互动，即可获得抽奖机会</p>
            <div><img src="/subject/live/button_1.png" @click="live800" /></div>
          </div>
          <div class="groupItem">
            <h1>如何抽奖</h1>
            <p>登录该页面，即可在下方转盘开始抽奖，所获奖励，会及时到账</p>
            <div v-if="userStatus === 0">
              <img src="/subject/live/button_2.png" @click="loginMethod" />
            </div>
          </div>
        </div>
        <div class="draw">
          <LuckyWheel
            class="LuckyWheel"
            ref="myLucky"
            width="710px"
            height="710px"
            :prizes="prizes"
            :blocks="blocks"
            :buttons="buttons"
            @start="startCallback"
            @end="endCallback"
          />
          <img
            class="zpBottom"
            src="/subject/worldCup/wheel/zpBottom.png"
            alt=""
          />
          <div class="showPrize">
            <div class="spLeft">
              <p>您的抽奖次数:</p>
              <p>您抽中的大奖:</p>
            </div>
            <div v-if="userStatus === 0" class="spRight">
              <img @click="loginMethod" src="/subject/live/login.png" alt="" />
            </div>
            <div v-if="userStatus === 4" class="spRight">
              <p>即将揭晓，敬请期待...</p>
            </div>
            <div v-if="userStatus === 1 || userStatus === 2" class="spRight2">
              <p class="numP">{{ surplusnum }}次</p>
              <p class="scrollP">
                <span v-for="item in turntablelogs" :key="item.id">{{
                  item.value
                }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="controlDialog || activityDetailsDialog" class="diaShadow"></div>
    <div v-if="controlDialog" class="dialog">
      <h3 v-if="messageAll.messageTitle === 0">很遗憾</h3>
      <h3 v-if="messageAll.messageTitle === 1">恭喜！你获得</h3>
      <h3 class="double" v-if="messageAll.messageTitle === 3">
        <span>锦鲤附体！</span>
        <span>恭喜抽中最高大奖！</span>
      </h3>
      <p
        :class="
          messageAll.messageTitle === 1
            ? 'messagePyellow'
            : messageAll.messageTitle === 2 || messageAll.messageTitle === 3
            ? 'messagePred'
            : 'messagePBlack'
        "
      >
        {{ messageAll.messagePPP }}
      </p>
      <p style="margin-top: 10px">
        {{ messageAll.messageTitle === 1 ? "" : "赠金点" }}
      </p>
      <button @click="closerDialog" class="messageButton">
        {{ messageAll.messageButton }}
      </button>
      <img
        :src="
          messageAll.messageTitle === 0
            ? '/subject/worldCup/1.png'
            : messageAll.messageTitle === 1
            ? '/subject/worldCup/2.png'
            : '/subject/worldCup/3.png'
        "
        alt=""
      />
    </div>
    <div v-if="activityDetailsDialog" class="activityDetailsDialog">
      <img
        @click="
          () => {
            activityDetailsDialog = false;
          }
        "
        src="/subject/worldCup/closer2.png"
        alt=""
      />
      <div>
        <h2>活动详情</h2>
        <h3>红狮直播周 答题抽大奖</h3>
        <p>
          好消息！红狮直播即将开启，<span>0距离和名师互动</span>，交易密码轻松破解！
        </p>
        <p>
          在直播间与老师积极互动，就有机会获得转盘奖励！活动持续一周！每天都可来直播间参加活动！<span
            >奖励无限！</span
          >
        </p>
        <p>
          红狮直播的板块，已经筹备许久，为了更好的服务客户，我们也期待平台的客户朋友能和我们一起梦幻联动！
        </p>
        <p>详情如下：</p>
        <p>1、<span>参与条件：</span>平台所有用户</p>
        <p>2、<span>活动时间：</span>2023年2月20日-2月24日 每晚20:00</p>
        <p>3、<span>活动规则：</span></p>
        <p>
          重磅福利！红狮启富直播间推出【答题赢大奖】活动，直播时段老师会发起提问环节，用户可通过答题赢得转盘抽奖次数，答的越多，赢得越多，赠金即时到账！可交易，可提现！
          老师另外会在直播间抽取互动最积极的用户发放赠金！互动越多！机会越大！
        </p>
        <p>4、<span>如何参与：</span></p>
        <p>
          积极在直播间与老师互动，红狮直播间将不定时发起互动环节，在直播间对话框输入答案，积极互动，即可一键参与！内容涵盖行情预测、时事热点等多个话题。
        </p>
        <p>5、<span>奖励发放：</span></p>
        <p>
          答题后会由<span style="color: #f04f54">讲师助理</span
          >记录下用户ID，并在后台添加抽奖次数。
        </p>
        <p style="margin-top: 40px"><span>注意事项</span></p>
        <p>
          1. 每位用户可多次参与答题，抽奖次数可累计；<br />
          2. 用户需在活动有效期内完成抽奖，逾期作废；<br />
          3. 已入金的用户可直接使用赠送的美元现金和<span style="color: #f04f54"
            >美元赠金（即赠金点）</span
          >，未入金的用户，入金后方可使用；<br />
          4.凡推广商及其名下客户，不符合活动参与资格；<br />
          5.我平台保留随时修订、暂停、终止本活动及相关规则条款之权利及解释权；
        </p>
      </div>
    </div>
    <contactusVue></contactusVue>
  </div>
</template>
<script>
import {
  luckDraw,
  searchId,
  searchUserNum,
  searchUserHistory,
} from "../../../../api/info";
import ContactusVue from "../../Contactus.vue";
export default {
  name: "livePc",
  components:{
    ContactusVue,
  },
  data() {
    return {
      // 转盘插件变量
      blocks: [
        {
          padding: "50px",
          imgs: [
            {
              src: "/subject/worldCup/wheel/background.png",
              width: "710px",
              height: "710px",
              top: "0px",
            },
          ],
        },
      ],
      prizes: [],
      buttons: [
        {
          radius: "25%",
          pointer: true,
          imgs: [
            {
              src: `/subject/worldCup/wheel/zpButton.png`,
              top: "-120px",
              width: "215px",
              height: "240px",
            },
          ],
        },
      ],
      // 控制用户状态
      userStatus: 0,
      // 用户抽奖次数
      surplusnum: "",
      // 用户历史奖励
      turntablelogs: [],
      // 用户账号
      userAccount: "",
      screenWidth: null,
      // 提示内容
      messageAll: {
        messageTitle: 0,
        messagePPP: "",
        messageButton: "",
      },
      // 控制弹出dialog
      controlDialog: false,
      activityDetailsDialog: false,
      navBarFixed: false,
      scroll: ''
    };
  },
  created() {
    this.$emit("controlShow", true);
    console.log(!sessionStorage.getItem("account"));
    // 判断用户是否是自己登陆
    if (!sessionStorage.getItem("account")) {
      if (!this.$route.query.account) {
        this.userStatus = 0;
      } else {
        this.userStatus = 1;
        sessionStorage.setItem("account", this.$route.query.account);
        this.userAccount = this.$route.query.account;
        this.initUserNum(sessionStorage.getItem("account"));
        this.initUserHistory(sessionStorage.getItem("account"));
      }
    } else {
      this.userStatus = 1;
      this.initUserNum(sessionStorage.getItem("account"));
      this.initUserHistory(sessionStorage.getItem("account"));
    }
    // 判断是从A前还是A后跳转过来
    if(!sessionStorage.getItem('craig')){
      if(!this.$route.query.craig){
        sessionStorage.setItem('craig',window.location.origin);
      }else{
        sessionStorage.setItem('craig',this.$route.query.craig);
      }
    };
    // 查询转盘
    searchId(32).then((res) => {
      if (res.status === 0) {
        res.awardlist.forEach((item, index) => {
          const prize = {
            background: index % 2 === 1 ? "#fdf0e3" : "#fffbf5",
            imgs: [
              {
                src: item.awardicon,
                top: "20px",
              },
            ],
          };
          this.prizes.push(prize);
        });
      } else {
        this.$message({
          message: "获取转盘信息失败!",
          type: "warning",
        });
      }
    });
    // uc-登录wap返回后,跳转世界杯wap;
    this.screenWidth = document.body.clientWidth;
    if (this.screenWidth < 500) {
      this.$router.push({
        name: "liveWap",
      });
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
  },
  watch: {
    screenWidth: function (n) {
      if (n <= 500) {
        this.$router.push({
          name: "liveWap",
        });
      }
    },
  },
  methods: {
    // 返回上级页面
    goback(){
      window.location.href = sessionStorage.getItem('craig');
    },
    // 控制顶部的定位
    handleScroll() {
      this.scroll = document.documentElement.scrollTop + document.body.scrollTop; //获取滚动后的高度
      if(this.scroll > 90 ){
        this.navBarFixed = true;
      }else{
        this.navBarFixed = false;
      }
    },
    // 客服链接
    live800() {
      // window.open(
      //   "https://chat32.live800.com/live800/chatClient/chatbox.jsp?companyID=215184&configID=35519&jid=5377035377"
      // );
      window.open(this.$parent.live800UrlUc)
    },
    // 登录
    loginMethod() {
      // window.location.href =
      //   window.location.origin + "/uc/login?" + "worldcup=worldcup";
      // window.location.href =
      //   "http://test.www.rlcvipltd.com/uc/login?" + "live=livePc";
      window.location.href =
        window.location.origin + "/uc/login?" + "live=livePc";
    },
    startCallback() {
      // 模拟调用接口异步抽奖
      if (!sessionStorage.getItem("account")) {
        this.$message({
          message: "请先登录账号!",
          type: "warning",
        });
      } else {
        if (this.surplusnum > 0) {
          let initData = {
            Account: Number(sessionStorage.getItem("account")),
            TurntableId: 32,
          };
          luckDraw(initData).then((res) => {
            if (res.status === 0) {
              if (res.turntableLog) {
                // 调用抽奖组件的play方法开始游戏
                this.$refs.myLucky.play();
                if (res.turntableLog.awardname.indexOf("赠") !== -1) {
                  this.messageAll.messagePPP =
                    res.turntableLog.awardname.split("赠")[0];
                } else {
                  this.messageAll.messagePPP = res.turntableLog.awardname;
                }
                switch (res.turntableLog.awardname) {
                  case "1美元现金":
                    this.cjIndex = 0;
                    this.$refs.myLucky.stop(this.cjIndex);
                    this.messageAll.messageButton = "开心收下";
                    this.messageAll.messageTitle = 1;
                    break;
                  case "2美元现金":
                    this.cjIndex = 1;
                    this.$refs.myLucky.stop(this.cjIndex);
                    this.messageAll.messageButton = "开心收下";
                    this.messageAll.messageTitle = 1;
                    break;
                  case "3美元现金":
                    this.cjIndex = 2;
                    this.$refs.myLucky.stop(this.cjIndex);
                    this.messageAll.messageButton = "开心收下";
                    this.messageAll.messageTitle = 1;
                    break;
                  case "5美元现金":
                    this.cjIndex = 3;
                    this.$refs.myLucky.stop(this.cjIndex);
                    this.messageAll.messageButton = "开心收下";
                    this.messageAll.messageTitle = 1;
                    break;
                  case "10美元现金（不送出）":
                    this.cjIndex = 4;
                    this.$refs.myLucky.stop(this.cjIndex);
                    this.messageAll.messageButton = "开心收下";
                    this.messageAll.messageTitle = 1;
                    break;
                  case "50美元现金（不送出）":
                    this.cjIndex = 5;
                    this.$refs.myLucky.stop(this.cjIndex);
                    this.messageAll.messageButton = "开心收下";
                    this.messageAll.messageTitle = 1;
                    break;
                  case "100美元赠金点":
                    this.cjIndex = 6;
                    this.$refs.myLucky.stop(this.cjIndex);
                    this.messageAll.messageButton = "开心收下";
                    this.messageAll.messageTitle = 2;
                    break;
                  case "200美元赠金点":
                    this.cjIndex = 7;
                    this.$refs.myLucky.stop(this.cjIndex);
                    this.messageAll.messageButton = "开心收下";
                    this.messageAll.messageTitle = 2;
                    break;
                  case "300美元赠金点":
                    this.cjIndex = 8;
                    this.$refs.myLucky.stop(this.cjIndex);
                    this.messageAll.messageButton = "开心收下";
                    this.messageAll.messageTitle = 2;
                    break;
                  case "500美元赠金点":
                    this.cjIndex = 9;
                    this.$refs.myLucky.stop(this.cjIndex);
                    this.messageAll.messageButton = "收下好运";
                    this.messageAll.messageTitle = 3;
                    break;
                }
              } else {
                this.$message({
                  message: res.msg,
                  type: "warning",
                });
              }
            } else {
              this.$message({
                message: "抽取奖励失败！",
                type: "warning",
              });
            }
          });
        } else {
          this.$message({
            message: "您无可用的抽奖次数！",
            type: "warning",
          });
        }
      }
    },
    endCallback() {
      this.cjIndex = null;
      this.controlDialog = true;
      this.initUserNum(sessionStorage.getItem("account"));
    },
    // 关闭弹窗
    closerDialog() {
      this.controlDialog = false;
      this.messageAll.messagePPP = "";
      this.messageAll.messageButton = "";
      this.initUserHistory(sessionStorage.getItem("account"));
    },
    // 查询抽奖次数
    initUserNum(attr) {
      let initData = {
        TurntablenumStatus: {
          accountid: attr,
          turntableid: 32,
          platform: 1,
        },
      };
      searchUserNum(initData).then((res) => {
        if (res.status === 0) {
          this.surplusnum = res.surplusnum;
        }
      });
    },
    // 查询历史抽奖结果
    initUserHistory(attr) {
      let initData = {
        TurntablenumStatus: {
          accountid: attr,
          turntableid: 32,
          platform: 1,
        },
      };
      searchUserHistory(initData).then((res) => {
        if (res.status === 0) {
          let craigArr = [];
          res.turntablelogs.map((item) => {
            craigArr.push({
              value: item.awardname,
              id: item.id,
            });
          });
          let r = craigArr.filter((item) => {
            return item.value != "未抽中";
          });
          this.turntablelogs = r;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.tabbar {
  width: 100%;
  background-color: #ffffff;
  img {
    margin: 20px 50px;
    width: 130px;
  }
}
.navBarFixed {
  position: fixed;
  top: 0;
  z-index: 999;
}
.banner {
  font-size: 0;
   img {
    width: 100%;
   }
}
.BIG_BOX {
  width: 100%;
  height: auto;
  background: url("/subject/live/background.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding-bottom: 259px;
  .centerView {
    width: 1172px;
    margin: 0 auto;
    .title {
      text-align: center;
      margin: 69px 0 50px;
      p {
        font-size: 32px;
        color: #e7d4b5;
        margin-top: 15px;
      }
    }
    .groupList {
      display: flex;
      justify-content: space-between;
      margin-bottom: 107px;
      .groupItem {
        width: 508px;
        height: 370px;
        background: url("/subject/live/group.png") no-repeat;
        background-size: 100% 100%;
        h1 {
          font-size: 36px;
          color: #fb8f20;
          text-align: center;
          margin: 43px 0 21px;
        }
        p {
          width: 316px;
          font-size: 24px;
          margin: 0 auto;
          line-height: 40px;
        }
        > div {
          text-align: center;
          margin-top: 70px;
          > img {
            cursor: pointer;
          }
        }
        &:nth-child(2) {
          > div {
            margin-top: 30px;
          }
        }
      }
    }
    .draw {
      width: 100%;
      height: 1444px;
      background: url("/subject/live/draw.png") no-repeat;
      background-size: cover;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      box-sizing: border-box;
      position: relative;
      .zpBottom {
        margin-top: -110px;
      }
      .LuckyWheel {
        margin-top: 144px;
      }
      .showPrize {
        width: 60%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        position: absolute;
        bottom: 100px;
        .spLeft {
          p {
            font-size: 34px;
            color: #cc4c00;
            margin: 20px 0;
            font-weight: 600;
            letter-spacing: 1px;
          }
        }
        .spRight {
          img {
            cursor: pointer;
          }
          p {
            font-size: 22px;
            color: #fb8f20;
          }
        }
        .spRight2 {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: flex-start;
          height: 125px;
          box-sizing: border-box;
          p {
            font-size: 30px;
            color: #f33b1d;
            font-weight: 600;
          }
          .numP {
            margin-bottom: -10px;
          }
          .scrollP {
            overflow-y: auto;
            box-sizing: border-box;
            width: 300px;
            height: 40px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            color: #fc7d29;
            margin-top: 20px;
          }
          .scrollP::-webkit-scrollbar {
            display: inline-block;
            width: 5px !important;
            height: 5px !important;
            background-color: #eee;
            background-clip: content-box;
          }
          .scrollP::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background-color: #6d6d6c;
          }
        }
      }
    }
  }
}
.diaShadow {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(51, 51, 51, 0.5);
}
.dialog {
  width: 400px;
  height: 320px;
  background: url("/subject/worldCup/dialogBg2.png") no-repeat;
  background-size: contain;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h3 {
    color: #b51e00;
    font-size: 22px;
  }
  .double {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  p {
    font-size: 22px;
  }
  img {
    width: 260px;
    position: absolute;
    top: -90px;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .messagePyellow {
    font-size: 28px;
    font-weight: 600;
    color: #fca10c;
  }
  .messagePred {
    font-size: 28px;
    font-weight: 600;
    color: #f3001e;
  }
  .messagePBlack {
    color: black;
  }
  .messageButton {
    width: 220px;
    height: 60px;
    border: none;
    background-color: #fb7920;
    border-radius: 15px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    margin-top: 20px;
  }
}
.activityDetailsDialog {
  width: 1200px;
  height: 600px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 15px;
  box-sizing: border-box;
  padding: 20px;
  overflow-y: scroll;
  img {
    float: right;
    width: 20px;
  }
  h2 {
    font-size: 30px;
    text-align: center;
  }
  h3 {
    color: #f04f54;
    margin: 10px 0;
    font-size: 24px;
  }
  p {
    line-height: 35px;
    font-size: 22px;
    span {
      font-weight: 600;
    }
  }
}
</style>